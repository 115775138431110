import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const NoFundsModal = ({ show }) => {
    const navigate = useNavigate();

    return (
        <Modal show={show} onHide={() => navigate('/fixtures')}>
            <Modal.Header closeButton>
                <Modal.Title>Not Enough Bonds Available</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>There are not enough bonds for this match D:</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => navigate('/fixtures')}>
                    Go to fixtures
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NoFundsModal;