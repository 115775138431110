import React, { useState, useEffect } from 'react';
import { ENDPOINTS } from '../config/endpoints';
import UserRequestCard from '../components/UserRequestCard';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col, Container } from 'react-bootstrap';

const UserRequestsList = () => {
    const [requests, setRequests] = useState([]);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);

    const fetchRequests = async () => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        for (const key of keys) {
            if (key.includes('idToken')) {
                idToken = localStorage.getItem(key);
            }
        }
        try {
            console.log(user);
            const response = await axios.get(ENDPOINTS.API.REQUESTS_BY_USER(user),{
                headers: { Authorization: `Bearer ${idToken}` },
            });
            setRequests(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    useEffect(() => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        let user = null;
        
        for (const key of keys) {
          if (key.includes('idToken')) {
            idToken = localStorage.getItem(key);
          }
          if (key.includes('LastAuthUser')) {
            user = localStorage.getItem(key);
          }
        }
      
        if (idToken) {
          setToken(idToken);
          setUser(user);
        } else {
          console.error('No tokens found in localStorage');
        }
      }, []);

    useEffect(() => {
        if (user) {
           fetchRequests(); 
        }
    }, [user]);

    if (!user) {
        return <p>Loading requests...</p>;
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        {requests.length > 0 ? requests.map(request => (
                            <UserRequestCard key={request.request_id} userRequest={request} />
                        )) : <p>No requests found. Go bet on some fixtures!</p>}
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            
        </div>
    )
}

const UserRequestsPage = () => {
    return (
        <div>
            <h1>User Requests</h1>
            <UserRequestsList />
        </div>
    );
}

export default UserRequestsPage;