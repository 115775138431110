import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ENDPOINTS } from '../config/endpoints';
import userEvent from '@testing-library/user-event';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';

const WalletPage = () => {
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState("");
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);

    const handleAddFunds = async () => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        for (const key of keys) {
            if (key.includes('idToken')) {
                idToken = localStorage.getItem(key);
            }
        }
        const value = parseInt(amount);
        if (!isNaN(value) && value > 0) {
            try {
                const response = await axios.post(ENDPOINTS.API.WALLET_UPDATE(user), {value}, {
                    headers: { Authorization: `Bearer ${idToken}` }
                })
                fetchUserData();
            } catch (error) {
                console.error('Error updating wallet:', error);
            }
        }
    }

    const fetchUserData = async () => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        for (const key of keys) {
            if (key.includes('idToken')) {
                idToken = localStorage.getItem(key);
            }
        }
        try {
            const response = await axios.get(ENDPOINTS.API.USER_DATA(user), {
                headers: { Authorization: `Bearer ${idToken}` }
            });
            setBalance(response.data.user.funds);
            console.log(response.data.user.funds);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {
        if (user){
            fetchUserData();
        }
    }, [user]);

    useEffect(() => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        let user = null;
        
        for (const key of keys) {
          if (key.includes('idToken')) {
            idToken = localStorage.getItem(key);
          }
          if (key.includes('LastAuthUser')) {
            user = localStorage.getItem(key);
          }
        }
      
        if (idToken) {
          setToken(idToken);
          setUser(user);
        } else {
          console.error('No tokens found in localStorage');
        }
      }, []);

    if (!user) {
        return <p>Loading wallet...</p>;
    }

    return (
    <div>
        <Container className='mt-4'>
            <Row className='d-flex justify-content-center'>
                <Col className='col-6'>
                    <Card className='bg-light'>
                        <h1 className='mt-3'>Wallet of {user}</h1>
                        <p>Current Balance:</p>
                        <h3>${balance}</h3>
                        <Container className='mt-5'>
                          <input 
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder='Ingresa cantidad de Dinero a agregar a tu wallet'
                            />  
                        </Container>
                        
                        <Container className='my-3'>
                            <Button onClick={handleAddFunds}>Agregar</Button> 
                        </Container>
                    </Card>
                </Col>
            </Row>            
        </Container>
        
    </div>
    )
}

export default WalletPage;