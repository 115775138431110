import React from 'react';
import { Link } from 'react-router-dom';
import { ENDPOINTS } from '../config/endpoints';

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404 - Page Not Found</h1>
      <p style={styles.text}>Sorry, the page you're looking for doesn't exist.</p>
      <Link to={ENDPOINTS.NAVIGATION.HOME} style={styles.link}>
        Go Back Home
      </Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
  },
  header: {
    fontSize: '48px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '20px',
    marginBottom: '30px',
  },
  link: {
    fontSize: '18px',
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default NotFoundPage;
