import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ENDPOINTS } from '../config/endpoints';
import { Button, Container, Col, Row, Card } from 'react-bootstrap';
import axios from 'axios';

const ConfirmBetForm = ({transaction_data}) => {
    return (
        <Container className='mt-5'>
            <Row className='d-flex justify-content-center'>
                <Col className='col-6'>
                    <Card className='bg-light py-4'>
                        <form method='POST' action={transaction_data.transaction_url}>
                            <h1>Confirm Bet</h1>
                            <p>Winner: {transaction_data.result}</p>
                            <p>Bonds: {transaction_data.quantity}</p>
                            <p>Amount: ${transaction_data.quantity * 1000}</p>
                            <input type='hidden' name='token_ws' value={transaction_data.transaction_token} />
                            <Button className='mt-5' type="submit">Ir a pagar</Button>
                        </form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const ConfirmBetPage = () => {
    const { user } = useAuthenticator(context => [context.user]);
    const [transactionData, setTransactionData] = useState({});
    const location = useLocation();

    const fetchRequestData = async () => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        for (const key of keys) {
            if (key.includes('idToken')) {
                idToken = localStorage.getItem(key);
            }
        }
        const request_data = location.state.request_data;
        try {
            const response = await axios.get(`${ENDPOINTS.API.REQUEST_BY_ID(request_data.request_id)}`,
            {
                headers: { Authorization: `Bearer ${idToken}` },
            });
            console.log(response.data)
            setTransactionData(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchRequestData();
    }, []);

    return (
        <div>
            {transactionData ? <ConfirmBetForm transaction_data={transactionData} /> : <p>Loading...</p>}
        </div>
    );
}

export default ConfirmBetPage;