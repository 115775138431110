import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useAuthenticator } from '@aws-amplify/ui-react';

const FixtureCard = ({ fixture, onDetailsClick }) => {
  const { user } = useAuthenticator(context => [context.user]); 
  const navigate = useNavigate();

  const handlebet = () => {
    if (user) {
      navigate(`/bet/${fixture.id}`);
    } else {
      navigate('/login');
    }
  }

  return (
    <div className="fixture-card">
      <div className="team">
        <img 
          src={fixture.home_team.logo} 
          alt={`${fixture.home_team.name} logo`} 
          className="team-logo" 
        />
        <p>{fixture.home_team.name}</p>
      </div>
      <div className="vs">VS</div>
      <div className="team">
        <img 
          src={fixture.away_team.logo} 
          alt={`${fixture.away_team.name} logo`} 
          className="team-logo" 
        />
        <p>{fixture.away_team.name}</p>
      </div>
      <p className="fixture-date">{new Date(fixture.date).toLocaleDateString()}</p>
      
      <div className="button-container">
        <Button onClick={() => onDetailsClick(fixture.id)}>Details</Button>
        <Button onClick={handlebet}>Bet</Button>
      </div>

    </div>
  );
};

export default FixtureCard;
