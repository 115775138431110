import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const FixtureModal = ({ show, handleClose, fixture }) => {
  if (!fixture) return null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{fixture.home_team.name} vs {fixture.away_team.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Date:</strong> {new Date(fixture.date).toLocaleString()}</p>
        <p><strong>Referee:</strong> {fixture.referee || 'N/A'}</p>
        <p><strong>Status:</strong> {fixture.status_long}</p>
        <p><strong>Goals:</strong> {fixture.goals_home || 'N/A'} - {fixture.goals_away || 'N/A'}</p>
        <p><strong>Location:</strong> {fixture.timezone}</p>
        <p><strong>Elapsed:</strong> {fixture.elapsed ? `${fixture.elapsed} minutes` : 'Not started'}</p>
        <p><strong>Bonds available:</strong> {fixture.bonds_available}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FixtureModal;
