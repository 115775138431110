import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './UserRequestCard.css';
import axios from 'axios';
import { ENDPOINTS } from '../config/endpoints';

const handleImprimirBoleta = async (request_id) => {
    console.log(request_id)
    try {
        const response = await axios.get(ENDPOINTS.API.DOWNLOAD_REQUEST_RECEIPT(request_id), {responseType: 'blob'});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receipt_${request_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading receipt:', error);
    }
}

const UserRequestCard = ({ userRequest }) => {
    return(
        <Card className={`my-4 ${userRequest.validated ? 'card-validated' : ''}`}>
            <Card.Body>
                <Card.Title> Request n° {userRequest.request_id}</Card.Title>
                {userRequest.result == '---' ? <Card.Text>Draw</Card.Text> : <Card.Text>Winner: {userRequest.result}</Card.Text>}
                <Card.Text>Bonds: {userRequest.quantity}</Card.Text>
                <Card.Text>{userRequest.status}</Card.Text>
                {userRequest.validated ? <Button onClick={() => handleImprimirBoleta(userRequest.request_id)}>Imprimir Boleta</Button> : null}
            </Card.Body>
        </Card>
    )
}

export default UserRequestCard;