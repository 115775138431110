import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import FixturesPage from './pages/FixturesPage';
import LoginPage from './pages/LoginPage';
import WalletPage from './pages/WalletPage';
import NotFoundPage from './pages/NotFoundPage';
import LogInRedirect from './pages/LogInRedirect';
import UserRequestsPage from './pages/UserRequestsPage';
import { ENDPOINTS } from './config/endpoints';
import BetPage from './pages/BetPage';
import ConfirmBetPage from './pages/ConfirmBetWebpayPage';
import CompletedPurchaseWebpayPage from './pages/CompletedPurchaseWebpayPage';
import CompletedPurchaseWalletPage from './pages/CompletedPurchaseWalletPage';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path={ENDPOINTS.NAVIGATION.HOME} element={<Navigate to={ENDPOINTS.NAVIGATION.FIXTURES} />} />

        <Route path={ENDPOINTS.NAVIGATION.FIXTURES} element={<FixturesPage />} />

        <Route path={ENDPOINTS.NAVIGATION.WALLET} element={<WalletPage />} />

        {/* Route to display the bet page for a specific fixture ARREGLAR PARA QUE ESTE CON ENDPOINTS*/}
        <Route path="/bet/:fixtureId" element={<BetPage />} />

        <Route path={ENDPOINTS.NAVIGATION.LOGINREDIRECT} element={<LogInRedirect />} />

        <Route path={ENDPOINTS.NAVIGATION.USER_REQUESTS} element={<UserRequestsPage />} />

        <Route path={ENDPOINTS.NAVIGATION.LOGIN} element={<LoginPage />} />

        <Route path={ENDPOINTS.NAVIGATION.CONFIRM_BET_WEBPAY} element={<ConfirmBetPage />} />

        <Route path={ENDPOINTS.NAVIGATION.COMPLETED_PURCHASE_WEBPAY} element ={<CompletedPurchaseWebpayPage />}/>

        <Route path={ENDPOINTS.NAVIGATION.COMPLETED_PURCHASE_WALLET} element ={<CompletedPurchaseWalletPage />}/>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
