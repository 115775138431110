import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from "react";
import axios from "axios";
import { ENDPOINTS } from "../config/endpoints";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from "@aws-amplify/auth";
import { Container } from "react-bootstrap";
const LoginPage = () => {
    const navigate = useNavigate();
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const { user } = useAuthenticator(context => [context.user]);

    useEffect(() => {
        if (authStatus === 'authenticated'){
            registerUser(); 
        }
    }, [user])

    const registerUser = async () => {
        try {
            await axios.post(ENDPOINTS.API.LOGIN, {
                email: user.username,
                funds: 10000,
            },
                {
                    headers: {
                        Authorization: `Bearer ${user.idToken}`,
                    },
                }
            );
        } catch (error) {
            console.error('Error al registrar el usuario:', error);
        }
        navigate('/fixtures');
    };

    const customFormFields = {
        signUp: {
            email: {
            placeholder: 'Email',
            label: 'Email',
            type: 'email',
            required: true,
            },
        },
    };

    return (
        <Container className="my-5">
            <Authenticator formFields={customFormFields}></Authenticator >
        </Container>
    );
};

export default LoginPage;