import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { ENDPOINTS } from '../config/endpoints';
import { Button, Card, Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';

const Recommendations = ({ recommendations }) => {
    console.log(recommendations)
    return (
        <div>
            <h2>Recommendations</h2>
            {recommendations.length === 0 ? (
                <p>No recommendations available.</p>
            ) : (
                <div>
                {recommendations.map((rec, index) => (
                    <Container className='my-3'>
                        <Row className='d-flex justify-content-center'>
                            <Col className='col-6'>
                                <Card className='pt-3 pb-2 bg-light'>
                                    <p><strong>Match:</strong> {rec.home_team_name} vs {rec.away_team_name}</p>
                                    <p><strong>Date:</strong> {new Date(rec.date).toLocaleString()}</p>
                                    <p><strong>League:</strong> {rec.league_id}</p>
                                    <p><strong>Ponderation:</strong> {rec.ponderation.toFixed(2)}</p>
                                </Card>
                            </Col>
                        </Row>
                    </Container>                   
                ))}
                </ div>
            )}
        </div>
    );
};

const CompletedPurchaseWebpayPage = () => {
    const [searchParams] = useSearchParams();
    const [transactionData, setTransactionData] = useState({});
    const [betData, setBetData] = useState({});
    const [recommendations, setRecommendations] = useState([]); // New state for recommendations
    const navigate = useNavigate();

    const CommitPurchase = async () => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        for (const key of keys) {
            if (key.includes('idToken')) {
                idToken = localStorage.getItem(key);
            }
        }
        const token = searchParams.get('token_ws');
        const TBK_TOKEN = searchParams.get('TBK_TOKEN');
        console.log(token);
        try {
            const response = await axios.post(`${ENDPOINTS.API.WEBPAY_COMMIT_TRANSACTION}`, { 
                token_ws: token, 
                TBK_TOKEN: TBK_TOKEN 
            }, {
                headers: { Authorization: `Bearer ${idToken}` }
            }
        );
            console.log(response.data);
            setTransactionData(response.data);
            setBetData(response.data.request);

            if (response.data.recommendations) {
                setRecommendations(response.data.recommendations);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        CommitPurchase();
    }, []);

    return (
        <>
            <Container className='my-3'>
                <Row className='d-flex justify-content-center'>
                    <Col className='col-8'>
                        <Card className='pt-3 pb-3 bg-light'>
                            <h1>Completed Bet Purchase</h1>
                            {betData.validated === true ? (
                                <>
                                    <h4 className='text-success'>{transactionData.message}</h4>
                                    <p>Pagado: ${betData.quantity * 1000}</p>
                                </>
                                
                            ) : (
                                <>
                                    <h4 className='text-danger'>{transactionData.message}</h4>
                                    <p>Porque hiciste eso?</p>
                                </>
                                
                            )}
                            <Container>
                                <Button onClick={() => navigate("/fixtures")}>Volver a los fixtures</Button>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container> 
            <Recommendations recommendations={recommendations} />
        </>
        
    );
};

export default CompletedPurchaseWebpayPage;
