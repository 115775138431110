const BACKEND_URL = "https://api.coolgoat.ink";

const ENDPOINTS = {
    API: {
        CREATE_REQUEST: `${BACKEND_URL}/api/requests/create/`,
        CREATE_REQUEST_RELATION: `${BACKEND_URL}/api/request/create/relation/`,
        REGISTER: `${BACKEND_URL}/auth/users/signup/`,
        LOGIN: `${BACKEND_URL}/auth/users/create/`,
        LOGOUT: `${BACKEND_URL}/auth/users/logout/`,
        AUTH0_CALLBACK: `${BACKEND_URL}/auth/users/auth0/callback/`,
        AUTH0_LOGOUT: `${BACKEND_URL}/auth/users/auth0/logout/`,
        FIXTURES: `${BACKEND_URL}/api/fixtures/`,
        BONDS: `${BACKEND_URL}/api/bonds/`,
        VALIDATION: `${BACKEND_URL}/api/fixtures/validate/`,
        REQUESTS: `${BACKEND_URL}/api/fixtures/requests/`,
        ODDS: `${BACKEND_URL}/api/odds`,
        WEBPAY_CREATE_TRANSACTION: `${BACKEND_URL}/api/webpay/create-payment/`,
        WEBPAY_COMMIT_TRANSACTION: `${BACKEND_URL}/api/webpay/commit-payment/`,
        WALLET_CONFIRM_TRANSACTION: `${BACKEND_URL}/api/requests/confirm-payment-wallet/`,
        
        USER_DATA: (user) => `${BACKEND_URL}/auth/users/${user}/`,
        WALLET_UPDATE: (user) => `${BACKEND_URL}/auth/users/${user}/wallet/`,
        REQUESTS_BY_USER: (user) => `${BACKEND_URL}/api/requests/user/${user}`,
        REQUEST_BY_ID: (request_id) => `${BACKEND_URL}/api/requests/details/${request_id}/`,
        DOWNLOAD_REQUEST_RECEIPT: (request_id) => `${BACKEND_URL}/api/requests/download-receipt/${request_id}/`,
        
        FIXTURES_FILTER: (home, visit, startDate, endDate) => {
            let url = `${BACKEND_URL}/api/fixtures/?`;
            if (home) url += `home=${home}&`;
            if (visit) url += `visit=${visit}&`;
            if (startDate && endDate) url += `start_date=${startDate}&end_date=${endDate}&`;
            return url.slice(0, -1);
        },
        FIXTURES_BY_ID: (fixture_id) => `${BACKEND_URL}/api/fixtures/${fixture_id}`,
    },
    NAVIGATION: {
        HOME: '/',
        LOGIN: '/login',
        REGISTER: '/register',
        PROFILE: '/profile',
        FIXTURES: '/fixtures',
        BONDS: '/bonds',
        VALIDATION: '/validation',
        LOGOUT: '/logout',
        WALLET: '/wallet',
        LOGINREDIRECT: '/loginredirect',
        USER_REQUESTS: '/requests',
        CONFIRM_BET_WEBPAY: '/confirm-bet/webpay',
        COMPLETED_PURCHASE_WEBPAY: '/completed-purchase/webpay',
        COMPLETED_PURCHASE_WALLET: '/completed-purchase/wallet',
        
        BONDS_BY_ID: (bond_id) => `/bonds/${bond_id}`,
        FIXTURES_BY_LEAGUE: (league_id) => `/fixtures/league/${league_id}`,
        FIXTURES_BY_TEAM: (team_id) => `/fixtures/team/${team_id}`,
    }
};

export { BACKEND_URL, ENDPOINTS };
