import React from 'react';
import { Button, Col } from 'react-bootstrap';

const PageSelector = ({ currentPage, totalPages, onPageChange, hasNextPage, hasPreviousPage }) => {
  
  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(totalPages);
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <Button className='mx-1' onClick={handleFirstPage} disabled={currentPage === 1}>
        First
      </Button>
      <Button className='mx-1' onClick={handlePreviousPage} disabled={!hasPreviousPage}>
        Previous
      </Button>
      <Col className='align-self-center'>
          Page {currentPage} of {totalPages}
      </Col>
      
      <Button className='mx-1' onClick={handleNextPage} disabled={!hasNextPage}>
        Next
      </Button>
      <Button className='mx-1' onClick={handleLastPage} disabled={currentPage === totalPages}>
        Last
      </Button>
    </div>
  );
};

export default PageSelector;
