const awsExports = {
    aws_cognito_region: 'sa-east-1',
    aws_project_region: 'sa-east-1',
    aws_user_pools_id: 'sa-east-1_rxvz9hzfu',
    aws_user_pools_web_client_id: '6isd0lobgtkne1p9ft7vgs8upg',
    oauth: {
        domain: 'coolgoatusers.auth.sa-east-1.amazoncognito.com',
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: 'http://localhost:3000/fixtures',
        redirectSignOut: 'http://localhost:3000/fixtures',
        responseType: 'code',
    },
    aws_cognito_sign_in_username_attributes: ['email'], 
};

export default awsExports;
