import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FixturesFilter from '../components/FixturesFilter';
import PageSelector from '../components/PageSelector';
import FixtureCard from '../components/FixtureCard';
import FixtureModal from '../components/FixtureModal';
import { ENDPOINTS } from '../config/endpoints';
import { Authenticator } from '@aws-amplify/ui-react'; // Amplify Auth component
import { Container, Button } from 'react-bootstrap';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles

const FixturesPage = () => {
  const [fixtures, setFixtures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedFixture, setSelectedFixture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const fetchFixtures = async (page = 1, filters = {}, pageSize = 25) => {
    try {
      const params = { page, page_size: pageSize, ...filters };

      // Ahora hacemos la llamada con el token
      const response = await axios.get(ENDPOINTS.API.FIXTURES, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setFixtures(response.data.results);
      setTotalPages(Math.ceil(response.data.count / pageSize));
      setHasNextPage(!!response.data.next);
      setHasPreviousPage(!!response.data.previous);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching fixtures:', error);
    }
  };

  const handleFilter = (newFilters) => {
    setFilters(newFilters);
    fetchFixtures(1, newFilters, pageSize);
  };

  const handlePageChange = (page) => {
    fetchFixtures(page, filters, pageSize);
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setPageSize(newSize);
    setTotalPages(Math.ceil(fixtures.length / newSize));
    setCurrentPage(1);
  };

  const handleDetailsClick = (fixtureId) => {
    const fixture = fixtures.find(f => f.id === fixtureId);
    setSelectedFixture(fixture);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFixture(null);
  };


  useEffect(() => {
    const keys = Object.keys(localStorage);
    let idToken = null;
    let user = null;

    for (const key of keys) {
      if (key.includes('idToken')) {
        idToken = localStorage.getItem(key);
      }
      if (key.includes('LastAuthUser')) {
        user = localStorage.getItem(key);
      }
    }

    if (idToken) {
      setToken(idToken);
      setUser(user);
    } else {
      console.error('No tokens found in localStorage');
    }
  }, []);

  useEffect(() => {
    fetchFixtures(currentPage, filters, pageSize);
  }, [currentPage, filters, pageSize, token]);


  //const paginatedFixtures = fixtures.slice(
  //  (currentPage - 1) * pageSize,
  //  currentPage * pageSize
  //);

  return (
    <div className="fixtures-page">
      <Container className="my-3">
        <h1>All the fixtures!!</h1>
      </ Container>
      <Container>
        <Button onClick={() => setIsFilterVisible(!isFilterVisible)}>
        {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
        </Button>
      </Container>
    
      {isFilterVisible && <FixturesFilter onFilter={handleFilter} />}

      <div className="fixtures-grid">
        {fixtures.map(fixture => (
          <FixtureCard
            key={fixture.id}
            fixture={fixture}
            onDetailsClick={handleDetailsClick}
          />
        ))}
      </div>
      
      <Container className='d-flex justify-content-center mb-3'>
        <PageSelector
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
        />
      </Container>
      

      <FixtureModal
        show={isModalOpen}
        handleClose={closeModal}
        fixture={selectedFixture}
      />
    </div>
  );
};

export default FixturesPage;
